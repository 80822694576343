import NotebookItems from "./notebook-items";

const NotebookList: React.FC = () => {
    const data = [
        {
            notebookTitle: 'Web Development!',
            notebookImg: require('./../../images/notebook.png').default
        },
        {
            notebookTitle: 'Deep Dive - Algorithms',
            notebookImg: require('./../../images/nb2.png').default
        },
    ]

    return <div>
        <span className="notebook-heading">Notebooks (Like IPYNB but for Javascript, HTML and CSS) Coming soon!</span>

        <div className="row d-flex justify-content-start">
            {data.map((val, index) => {
                return <div className="col-md-6 col-lg-4 col-12 col-sm-6" key={index}>
                    <NotebookItems {...val} />
                </div>
            })}
        </div>
    </div>
};

export default NotebookList;
