import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import TutorialForm from "./tutorial-form";

const PostTutorials: React.FC = () => {
  const { loginUser } = useActions();
  const { data: authData } = useTypedSelector((state) => state.authReducer);
  const [userData, setUserData] = useState<any[]>([]);

  useEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    setUserData(authData);
  });

  return (
    <div>
      <Header {...userData[0]} />
      {userData &&
      userData[0] &&
      userData[0]["googleId"] === "103948883952043218214" ? (
        <div>
        <div className="d-flex flex-column flex-md-row justify-content-between w-50">
          <Link to="/admin" className="post">
            Post
          </Link>
          <Link to="/tutorials" className="post">
            Tutorials
          </Link>
          <Link to="/contact-form">Contact</Link>
          <div className="all_users">All Users</div>
          <div className="location">User Location</div>
          
        </div>
        <div><TutorialForm /></div>
        </div>

      ) : (
        <div className="d-flex justify-content-center align-items-center">
          Only Admin can access this page! 😔
        </div>
      )}
    </div>
  );
};

export default PostTutorials;
