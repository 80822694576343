import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useActions } from '../../hooks/useAction';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import './header.css';

interface AuthProps {
  googleId: string;
  profileImage: string;
}

const Header: React.FC<AuthProps> = ({ googleId, profileImage }) => {
  const { loginUser, logoutUser } = useActions();
  const { data } = useTypedSelector((state) => state.authReducer);
  const [result, setResult] = useState<any[]>([]);
  const [showDropdown, setShowDropdown] = useState<Boolean>(false);
  useEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    setResult(data);
  }, [result]);

  return (
    <div
      onMouseLeave={() => setShowDropdown(false)}
      className='header-fixed pb-3 '
    >
      <nav className='navbar navbar-expand-lg p-0'>
        <div className='d-flex mt-3'>
          <Link to='/' className='navbar-module--brand'>
            <figure className='navbar-module--js font-weight-bold'>
              <p>JS</p>
            </figure>
          </Link>
          <p className='navbar-module--title'>
            <strong className='navbar-module--name'>Jelena Srebric</strong>
            <em className='navbar-module--tagline'>Ph.D. MIT</em>
          </p>
        </div>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <Link to='/'>
                <p className='un ml-5 '></p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/'>
                <p className='un ml-5 '></p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/'>
                <p className='un ml-5 '></p>
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/'>
                <p className='un ml-5 '>Home</p>
              </Link>
            </li>
            <li className='nav-item'>
              <p className='un ml-5'>Workshops</p>
            </li>

            <li className='nav-item'>
              <p className='un ml-5'>Publications</p>
            </li>
            <li className='nav-item'>
              <p className='un ml-5'>Research</p>
            </li>
            <li className='nav-item'>
              <p className='un ml-5'>Honors</p>
            </li>
            <Link to='/blog'>
              <p className='un ml-5 '>Blog</p>
            </Link>
          </ul>
        </div>
      </nav>

      {/* <div className='header d-flex justify-content-between'>
        <div className='d-flex mt-3'>
          <Link to='/' className='navbar-module--brand'>
            <figure className='navbar-module--js font-weight-bold'>
              <p>JS</p>
            </figure>
          </Link>
          <p className='navbar-module--title'>
            <strong className='navbar-module--name'>Jelena Srebric</strong>
            <em className='navbar-module--tagline'>Ph.D. MIT</em>
          </p>
        </div>
        <div
          role='button'
          className='d-flex align-items-center justify-content-between mt-2'
        >
          <Link to='/'>
            <p className='un active ml-5 '>Home</p>
          </Link>
          <a href='http://localhost:3001/' target='_blank'>
            <p className='un ml-5'>Workshops</p>
          </a>
          <p className='un ml-5'>Work</p>
          <p className='un ml-5'>Publications</p>
          <p className='un ml-5'>Research</p>
          <p className='un ml-5'>Honors & Awards</p>
          <Link to='/view-all-posts'>
            <p className='un ml-5'>Blog</p>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
