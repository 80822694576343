export enum ActionType { 
    SEARCH_BLOG_POSTS = 'SEARCH_BLOG_POSTS',
    BLOG_POSTS_SUCCESS = 'BLOG_POSTS_SUCCESS',
    BLOG_POSTS_ERROR = 'BLOG_POSTS_ERROR',

    POST_BLOG = 'POST_BLOG',
    POST_BLOG_SUCCESS = 'POST_BLOG_SUCCESS',
    POST_BLOG_ERROR = 'POST_BLOG_ERROR',

    SEARCH_TUTORIAL_POSTS = 'SEARCH_TUTORIAL_POSTS',
    TUTORIAL_POSTS_SUCCESS = 'TUTORIAL_POSTS_SUCCESS',
    TUTORIAL_POSTS_ERROR = 'TUTORIAL_POSTS_ERROR',

    POST_TUTORIAL = 'POST_TUTORIAL',
    POST_TUTORIAL_SUCCESS = 'POST_TUTORIAL_SUCCESS',
    POST_TUTORIAL_ERROR = 'POST_TUTORIAL_ERROR',


    GET_TUTORIAL = 'GET_TUTORIAL',
    GET_TUTORIAL_SUCCESS = 'GET_TUTORIAL_SUCCESS',
    GET_TUTORIAL_ERROR = 'GET_TUTORIAL_ERROR',

    LOGIN_USER = 'LOGIN_USER',
    LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
    LOGIN_USER_ERROR = 'LOGIN_USER_ERROR',

    LOGOUT_USER = 'LOGOUT_USER',
    LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS',
    LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR',

    UPDATE_USER = 'UPDATE_USER',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

    GET_POST = 'GET_POST',
    GET_POST_SUCCESS = 'GET_POST_SUCCESS',
    GET_POST_ERROR = 'GET_POST_ERROR',

    POST_CONTACTUS_SUCCESS='post_CONTACTUS_success',
    POST_CONTACTUS='post_CONTACTUS',
    POST_CONTACTUS_ERROR='post_CONTACTUS_error',
    SEARCH_CONTACTUS='search_CONTACTUS',
    SEARCH_CONTACTUS_SUCCESS='search_CONTACTUS_success',
    SEARCH_CONTACTUS_ERROR='search_CONTACTUS_error',

    DELETE_USER="DELETE_USER",
    DELETE_USER_SUCCESS='DELETE_USER_SUCCESS',
    DELETE_USER_ERROR='DELETE_USER_ERROR',

};