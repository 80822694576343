import BlogCardItem from "../components/blogCard/blog-card-item";
// import SearchBar from "../components/search-bar";
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useState } from "react";
import { useEffect } from "react";
import Header from "../components/header/header";
// import { useSockets } from "../context/socket.context";
import Loading from "../components/Loading";

const ViewAllPosts: React.FC = () => {
    const { searchBlogPosts } = useActions();
    const history = useHistory();
    const { data, loading } = useTypedSelector((state) => state.blogPostReducer)
    const [result, setResult] = useState<any[]>([]);
    // const { socket } = useSockets();
    const { loginUser } = useActions();
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);
    const { search } = useLocation()
    const values = queryString.parse(search)

    useEffect(() => {
        loginUser()
    },[]);

    useEffect(() => {
        setUserData(authData)
    });

    useEffect(() => {
        // socket.on('bookmark_action', (socket) => {
        //     loginUser();
        //     setUserData(authData)
        // })
    }, [])


    useEffect(() => {
        searchBlogPosts(values.tags);
    }, []);

    useEffect(() => {
        setResult(data)
    }, [data]);

    return <div className="">
        <Header {...userData[0]} />
        <i role="button" className="fas fa-arrow-left" onClick={history.goBack}></i>
        {!loading ? <div style={{ flex: 1 }} className="row d-flex justify-content-center justify-content-md-start">
            {result.map((val, index) => {
                userData[0] && userData[0]['bookmarks'] && userData[0] && userData[0]['bookmarks'].map((id: any, _: number) => {
                    if (id == val._id) {
                        val.bookmarked = true
                }
                })
                return <div className="col-lg-4 mt-2" key={index}>
                    <BlogCardItem {...val} googleId={userData && userData[0] && userData[0]['_id']} bookmarks={userData && userData[0] && userData[0]['bookmarks']}/>
                </div>
            })}
        </div>: <Loading />}
    </div>
};

export default ViewAllPosts;
