import { Provider } from "react-redux";
import { store } from './../states';
import  { BrowserRouter, Route, Switch} from 'react-router-dom';

import Admin from "../pages/admin";
// import BlogCard from "./blogCard/blog-card";
// import CategoryList from "./categories/category-list";
import HomePage from "../pages/home-page";
import './app.css';
import ViewAllPosts from "../pages/view-all-posts";
import Dashboard from '../pages/dashboard';
import IndvPostPage from '../pages/indv-post-page';
import IndvTutorialPage from "../pages/indv-tutorial-page";
import ContactWidget from "./contact/ContactWidget";
import Bookmarks from "../pages/bookmarks";
import PostForm from "../pages/ForMe/post-form";
import Contact from "../pages/ForMe/contact";
import PostTutorials from "../pages/ForMe/post-tutorials";
import Settings from "../pages/settings";
import Blog from "../pages/blog";

const App: React.FC = () => {
    return <Provider store={store}>
        <div className="container">
        <div>
            <ContactWidget />
        </div>

            <BrowserRouter>
                <Switch>
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/course/:id" exact component={IndvTutorialPage} />
                    <Route path="/" exact component={HomePage} />
                    <Route path="/view-all-posts" exact component={ViewAllPosts} />
                    <Route path="/posts/:id" exact component={IndvPostPage} />
                    <Route path="/admin" exact component={Admin} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/admin" exact component={PostForm} />
                    <Route path="/tutorials" exact component={PostTutorials} />
                    <Route path="/contact-form" exact component={Contact} />
                    <Route path="/:id/bookmarks" exact component={Bookmarks} />
                    <Route path="/:id/settings" exact component={Settings} />
                    <Route path="*" component={HomePage}/>
                </Switch>
            </BrowserRouter>
        </div>
    </Provider>
};

export default App;
