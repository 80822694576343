import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useActions } from '../../hooks/useAction';
// import { useSockets } from '../../context/socket.context';
import moment from "moment";
import './blogCard.css'

interface BlogCardItemProps {
    title: String;
    tags?: String[];
    createdAt: string;
    readTime: number;
    bookmarked: Boolean;
    body: String;
    _id: string;
}

interface UserID {
    googleId: string;
}

interface Bookmarks {
    bookmarks: []
}

type Props = BlogCardItemProps &  UserID & Bookmarks

const BlogCardItem: React.FC<Props> = ({title, tags, createdAt, body, bookmarked, _id, googleId, bookmarks}) => {
    const { updateUser, searchBlogPosts } = useActions();
    const [bookmarked_, setBookmarked_] = useState<boolean>(false);
    // const { socket } = useSockets();
    const readTime = Math.ceil(body.split(' ').length / 200);
    const [modal, setModal] = useState<boolean>(false);
    const onBookmark = (e: any) => {
        e.stopPropagation();
        
        if(googleId) {
            if(!bookmarked_) {
                // socket.emit('bookmark');
                updateUser([...bookmarks, _id], googleId);
                // socket.emit('bookmark');
            } else {
                // socket.emit('bookmark');
                const bookmarkRemoved = bookmarks.filter(bm => bm !== _id);
                updateUser([...bookmarkRemoved], googleId);
                searchBlogPosts();
                // socket.emit('bookmark');
            }
        } else {
            setModal(!modal)
            setTimeout(() => {
                setModal(false)
            }, 2000)
        }
    }

    useEffect(() => {
        if(bookmarked) {
            setBookmarked_(!bookmarked_)
        }
    }, [bookmarked])

    return <div className="container mt-3 mt-lg-0 shadow-sm text-center indv-card">
        <Link to={`/posts/${_id}`} >
            <div className="d-flex pt-2">
                
                <div className="lead line mt-2" style={{ backgroundColor: '#142664'}}>
                    <div className="ml-1 mt-2" style={{ 
                    width: '20rem', 
                    textAlign: 'left', 
                    }}>
                        {tags?.map((val, index) => {
                            return <span key={index} className="tags ml-1 m-0">{val}</span>
                        })}
                    </div>
                </div>
                <div className="ml-2 m-0 p-0 title-post" style={{textOverflow: 'ellipsis', 
                    overflow: 'hidden', 
                    whiteSpace: 'nowrap'}}>
                    {title}
                </div>
            </div>
            <div className="text-left mt-2 post-timing d-flex ">
                <div className="flex-column mr-0 mr-3">
                    <div className="post-timing-text">Posted on</div>
                    <div className="post-timing-text post-timing-date">
                        {moment(createdAt).format("lll")}
                        {/* {createdAt && createdAt.replace('T', ' ').slice(0, 16)} */}
                    </div>
                </div>
                <div className=""><i className="far fa-clock"></i></div>
                <div className="flex-column ml-0 ml-3">
                    <div className="post-timing-text">Read time</div>
                    <div className="post-timing-text post-timing-date">{readTime} {readTime == 1 ? 'min' : 'mins'}</div>
                </div>
            </div>
            </Link>
            
        </div>
};

export default BlogCardItem;
