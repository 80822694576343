import './notebooks.css';

interface NotebookItemsProps {
    notebookTitle: string;
    notebookImg: string;
}

const NotebookItems: React.FC<NotebookItemsProps> = ({notebookTitle, notebookImg}) => {
    return <div className="notebook-card border shadow-sm mt-3 mt-md-2">
        <div className="">
            <img src={notebookImg} alt="notebook1" />
            <span className="text">{notebookTitle}</span>
        </div>
    </div>
};

export default NotebookItems;
