import MDEditor from '@uiw/react-md-editor';
import { Field, Form } from "react-final-form";
import { useActions } from '../../hooks/useAction';

const TutorialForm: React.FC = () => {
    const { postTutorial } = useActions();
    return <div>
        <Form onSubmit={formValues => {
            postTutorial(formValues)
        }}>
            {( {handleSubmit} ) => {
                return <form onSubmit={handleSubmit}>
                    <Field name='tutorialTopic'>
                        {({ input, label }) => {
                            return <div>
                                <input autoComplete="off" id="title" className="form-control mt-4" placeholder="Topic title" type="text" {...input} />
                            </div>
                        }}
                    </Field>
                    <Field name='title'>
                        {({ input, label }) => {
                            return <div>
                                <input autoComplete="off" id="title" className="form-control mt-4" placeholder=" title" type="text" {...input} />
                            </div>
                        }}
                    </Field>
                    <Field name='body'>
                        {({ input }) => {
                            return <div className="mt-4">
                                <MDEditor {...input} />
                                {/* <textarea placeholder="Blog post body" className="form-control mt-4" id="exampleFormControlTextarea1" rows={5} {...input} /> */}
                            </div>
                        }}
                    </Field>
                    <button className="btn btn-dark mt-4" type="submit">Submit!</button>
                </form>
            }}
        </Form>
    </div>
}

export default TutorialForm;