import MDEditor from '@uiw/react-md-editor';
import { Field, Form } from "react-final-form";
import { useActions } from '../../hooks/useAction';

const PostForm: React.FC = () => {
    const { postBlog } = useActions();
    return <div>
        <Form onSubmit={formValues => {
            postBlog(formValues)
        }}>
            {( {handleSubmit} ) => {
                return <form onSubmit={handleSubmit}>
                    <Field name='title'>
                        {({ input, label }) => {
                            return <div>
                                <input autoComplete="off" id="title" className="form-control mt-4" placeholder="Blog post title" type="text" {...input} />
                            </div>
                        }}
                    </Field>
                    <Field name='body'>
                        {({ input }) => {
                            return <div className="mt-4">
                                <MDEditor {...input} />
                                {/* <textarea placeholder="Blog post body" className="form-control mt-4" id="exampleFormControlTextarea1" rows={5} {...input} /> */}
                            </div>
                        }}
                    </Field>
                    <Field name='tags' component='select' multiple={true}>
                        {({ input }) => {
                            return <div className="form-group mt-4">
                                    <select multiple className="form-control" id="exampleFormControlSelect2" {...input}>
                                        <option>Python</option>
                                        <option>Deep learning</option>
                                        <option>Algorithms</option>
                                        <option>Others</option>
                                        <option>JavaScript</option>
                                        <option>HTML/CSS</option>
                                        <option>Machine learning</option>
                                    </select>
                            </div>
                        }}
                    </Field>
                    
                    <button className="btn btn-dark mt-4" type="submit">Submit!</button>
                </form>
            }}
        </Form>
    </div>
}

export default PostForm;