import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/header/header";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { loginUser } from "../../states/action-creators";

const Contact: React.FC = () => {
  const { searchContactUs } = useActions();
  const { data, loading } = useTypedSelector((state) => state.contactUsReducer);

  const { data: authData } = useTypedSelector((state) => state.authReducer);
  const [userData, setUserData] = useState<any[]>([]);

  useEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    setUserData(authData);
  });

  useEffect(() => {
    searchContactUs();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      <Header {...userData[0]} />
      {userData &&
      userData[0] &&
      userData[0]["googleId"] === "103948883952043218214" ? (
        <div>
          <div className="d-flex flex-column flex-md-row justify-content-between w-50">
            <Link to="/admin" className="post">
              Post
            </Link>
            <Link to="/tutorials" className="post">
              Tutorials
            </Link>
            <Link to="/contact-form">Contact</Link>
            <div className="all_users">All Users</div>
            <div className="location">User Location</div>
          </div>
          <table className="table mt-3">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Message</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {data.map((val, i) => {
                return (
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{val.name}</td>
                    <td>{val.body}</td>
                    <td>
                      <a href={`mailto:${val.email}`}>
                        <HighlightEmail className="highlight">
                          {val.email}
                        </HighlightEmail>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          Only Admin can access this page! 😔
        </div>
      )}
    </div>
  );
};

export default Contact;

export const HighlightEmail = styled.span`
  padding: 0.2em 13.7px;
  line-height: 1.8em;
  &.highlight {
    font-weight: bolder;
    background: linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      ),
      linear-gradient(
        183deg,
        rgba(130, 255, 173, 0) 0%,
        rgba(130, 255, 173, 0.3) 7.9%,
        rgba(130, 255, 173, 0) 15%
      );
    /* padding: 0.6em 13.7px; */
    margin: 0;
    border-radius: 7.5px;
    text-shadow: -12px 12px 9.8px rgba(130, 255, 173, 0.7),
      21px -18.1px 7.3px rgba(255, 255, 255, 1),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }
`;
