import { Link } from "react-router-dom";
import bookmarks from "../../pages/bookmarks";

interface SidebarProps {
    googleId: string,
    bookmarks: [],
    _id: string,
    profileImage: string,
    displayName: string,
    email: string
}

interface LocationProp {
    location: string
}

type Props = SidebarProps & LocationProp

const Sidebar: React.FC<Props> = ({googleId, bookmarks, _id, profileImage, location, displayName, email}) => {
    return <div style={{ height: '100%'}} className="d-flex flex-column mt-4">
        <div className="">
            <div className={`d-flex align-items-center mb-3 ${location && location.split('/')[1] === 'dashboard' ? 'highlight-sidebar' : ''}`}>
                <i className="fas fa-home"></i>
                <span className="ml-2">
                <Link to='/dashboard' >
                        Home
                    </Link>
                </span>
            </div>
            <div className="d-flex align-items-center mb-3">
                <i className="fas fa-binoculars"></i>
                <span className="ml-2">
                <Link to='/view-all-posts' >
                    View All posts
                </Link>
                </span>
            </div>
            {/* <div className="d-flex align-items-center mb-3">
                <i className="fas fa-chalkboard-teacher"></i>
                <span className="ml-2">
                <Link to='/view-all-tutorials' >
                    View All Tutorials
                </Link>
                </span>
            </div> */}
            {/* <div className="d-flex align-items-center mb-3 disabled">
                <i className="fas fa-book"></i>
                <span className="ml-2">My Notebooks</span>
            </div>
            <div className="d-flex align-items-center mb-3 disabled">
                <i className="fas fa-pencil-alt"></i>
                <span className="ml-2">Create Notebook</span>
            </div>  */}
            <div className={`d-flex align-items-center mb-3 ${location && location.split('/')[2] === 'bookmarks' ? 'highlight-sidebar' : ''}`}>
                <i className="fas fa-bookmark"></i>
                <span className="ml-2">
                    <Link to={{ pathname: `/${googleId}/bookmarks`, state: { googleId, bookmarks, _id, profileImage }}} >
                        Bookmarks
                    </Link>
                </span>
            </div>
        </div>
        <div className={`d-flex align-items-center mb-3 ${location && location.split('/')[2] === 'settings' ? 'highlight-sidebar' : ''}`}>
            <i className="fas fa-cog"></i>
            <span className="ml-2"><Link to={{ pathname: `/${googleId}/settings`, state: { googleId, bookmarks, _id, profileImage, displayName, email }}} >
                        Settings
            </Link></span>
        </div>
    </div>
};

export default Sidebar;
