import styled from 'styled-components';
import { useState, useRef, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useActions } from '../../hooks/useAction';

interface ContactPopupProps {
  popup: (data: boolean) => void;
}

const ContactPopup: React.FC<ContactPopupProps> = ({ popup }) => {
  const [message, setMessage] = useState(false);
  const [messageTwo, setMessageTwo] = useState(false);
  const [messageThree, setMessageThree] = useState(false);
  const [messageThree_1, setMessageThree_1] = useState(false);
  const [messageFour, setMessageFour] = useState(false);
  const [messageFour_1, setMessageFour_1] = useState(false);
  const [messageFive, setMessageFive] = useState(false);
  const [messageFive_1, setMessageFive_1] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const [finalChat, setFinalChat] = useState(false);
  const { postContactUs } = useActions();
  const divRef = useRef<any>(null);

  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: 'smooth' });
  });

  useEffect(() => {
    if (messageFive) {
      postContactUs(name, body, email);
    }
  }, [messageFive]);

  const onKeyPressName = (event: any) => {
    let re = /^[a-zA-Z\s]+$/;
    if (re.test(event.target.value)) {
      if (event.key === 'Enter') {
        setName(event.target.value);
        event.target.value = '';
        setMessageThree(true);
      }
    }
  };

  const onKeyPressEmail = (event: any) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(event.target.value)) {
      if (event.key === 'Enter') {
        setEmail(event.target.value);
        event.target.value = '';
        setMessageFour(true);
      }
    } else {
    }
  };

  const onKeyPressMessage = (event: any) => {
    if (event.key === 'Enter') {
      setBody(event.target.value);
      event.target.value = '';
      setMessageFive(true);
    } else {
    }
  };

  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <ContactPopupContainer className='d-flex justify-content-between flex-column'>
      <div style={{ overflow: 'auto' }}>
        <div className='p-3 fs-6'>
          Thanks for visiting{' '}
          <mark style={{ backgroundColor: '#ff3860', color: 'white' }}>
            Jelena Srebric Webpage
          </mark>
        </div>
        {message ? (
          <div style={{ position: 'relative', bottom: '1rem' }} className='p-3'>
            A few questions before I get back to you!
          </div>
        ) : (
          ''
        )}
        {messageTwo ? (
          <div style={{ position: 'relative', bottom: '2rem' }} className='p-3'>
            <span className=' badge-imp-color'>
              What is your name? (Name should contain only characters(a-z/A-Z)
            </span>
          </div>
        ) : (
          ''
        )}
        <div className='d-none'>
          {setTimeout(() => {
            setMessage(true);
          }, 1000)}
        </div>
        <div className='d-none'>
          {setTimeout(() => {
            // setMessage(false)
            setMessageTwo(true);
          }, 2000)}
        </div>
        <div
          style={{ position: 'relative', bottom: '3rem' }}
          className='p-3 d-flex justify-content-end '
        >
          <span className={`${name ? 'badge-color' : ''}`}>{name}</span>
        </div>
        {messageThree ? (
          <div className='d-none'>
            {setTimeout(() => {
              // setMessage(false)
              setMessageThree_1(true);
            }, 1000)}
          </div>
        ) : (
          ''
        )}
        {messageThree_1 ? (
          <div style={{ position: 'relative', bottom: '4rem' }} className='p-3'>
            <span className='badge-imp-color'>
              Thanks, {name}. What is your email id? (Inappropriate email format
              won't be accepted)
            </span>
          </div>
        ) : (
          ''
        )}
        <div
          style={{ position: 'relative', bottom: '3rem' }}
          className='p-3 d-flex justify-content-end '
        >
          <span className={`${email ? 'badge-color' : ''}`}>{email}</span>
        </div>
        {messageFour ? (
          <div className='d-none'>
            {setTimeout(() => {
              // setMessage(false)
              setMessageFour_1(true);
            }, 1000)}
          </div>
        ) : (
          ''
        )}
        {messageFour_1 ? (
          <div style={{ position: 'relative', bottom: '4rem' }} className='p-3'>
            <span className='badge-imp-color'>
              Thanks, {name}. What brings you to My Website today? (Please type
              your entire message in the below input box)
            </span>
          </div>
        ) : (
          ''
        )}
        <div
          style={{ position: 'relative', bottom: '4rem' }}
          className='p-3 d-flex justify-content-end '
        >
          <span className={`${body ? 'badge-color' : ''}`}>{body}</span>
        </div>
        {messageFive ? (
          <div className='d-none'>
            {setTimeout(() => {
              setMessageFive_1(true);
              setFinalChat(true);
            }, 1000)}
          </div>
        ) : (
          ''
        )}
        {messageFive_1 ? (
          <div style={{ position: 'relative', bottom: '4rem' }} className='p-3'>
            <span className='badge-imp-color'>
              Thank you. I will contact you at {email} soon. Have a good day!
            </span>
          </div>
        ) : (
          ''
        )}

        {finalChat ? (
          <div className='d-none'>
            {setTimeout(() => {
              // setMessage(false)
              popup(false);
            }, 5000)}
          </div>
        ) : (
          ''
        )}

        <div ref={divRef}></div>
      </div>
      <div>
        <Form onSubmit={() => console.log('')}>
          {({ handleSubmit }) => {
            return (
              <form
                onKeyPress={onKeyPress}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {messageTwo && !messageThree_1 ? (
                  <Field name='name'>
                    {({ ...input }) => {
                      return (
                        <InputBox
                          autoComplete='off'
                          onKeyPress={onKeyPressName}
                          {...input}
                          placeholder={`Enter your ${messageTwo ? 'Name' : ''}`}
                          className='p-2'
                        />
                      );
                    }}
                  </Field>
                ) : (
                  ''
                )}
                {messageThree_1 && !messageFour_1 ? (
                  <Field name='email'>
                    {({ input: { value, onChange, ...input } }) => {
                      return (
                        <InputBox
                          autoComplete='off'
                          onKeyPress={onKeyPressEmail}
                          autoFocus
                          type='email'
                          // onChange={(e) => handleOnChange(e.target.value)}
                          {...input}
                          placeholder={`Enter your ${
                            messageThree ? 'Email' : ''
                          }`}
                          className='p-2'
                        />
                      );
                    }}
                  </Field>
                ) : (
                  ''
                )}
                {messageFour_1 ? (
                  <Field name='body'>
                    {({ input: { value, onChange, ...input } }) => {
                      return (
                        <InputBox
                          autoComplete='off'
                          onKeyPress={onKeyPressMessage}
                          autoFocus
                          // onChange={(e) => handleOnChange(e.target.value)}
                          {...input}
                          placeholder={`Enter your ${
                            messageFour ? 'Message' : ''
                          }`}
                          className='p-2'
                        />
                      );
                    }}
                  </Field>
                ) : (
                  ''
                )}
              </form>
            );
          }}
        </Form>
      </div>
    </ContactPopupContainer>
  );
};

export default ContactPopup;

export const ContactPopupContainer = styled.div`
  width: 18rem;
  height: 25rem;
  background-color: #fff;
  box-shadow: 5px 5px 25px 0 rgb(46 61 73 / 20%);
  border-radius: 1.5rem;
  transition: all 1.3s ease-out;
`;

export const InputBox = styled.input`
  /* color: #2e3d49; */
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 1.5rem;
  margin-top: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;
  outline: none;
  text-indent: 10px;

  &:active,
  :focus {
    border: 1px solid #ff3860;
  }
`;
