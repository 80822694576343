import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header/header"
import Sidebar from "../components/notebooks/sidebar";
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";

interface SettingProps {
    googleId: string,
    _id: string,
    profileImage: string,
    displayName: string
}


const Settings: React.FC = () => {
    const location = useLocation<SettingProps>();
    const { loginUser, deleteUser } = useActions();
    const val  = location.state
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);
    const location_ = useLocation();
    const [deleteAcc, setDeleteAcc] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<string>('');
    const [bye, setBye] = useState<boolean>();

    useEffect(() => {
        loginUser()
    },[]);

    useEffect(() => {
        setUserData(authData)
    });

    const onDeleteAcc = (verify: string) => {
        if(verify === (userData && userData[0] && userData[0].email)) {
            deleteUser(userData && userData[0] && userData[0]._id)
            setDeleteAcc(false)
            setBye(true)
            setTimeout(() => {
                window.location.href = "/";
            }, 2000)
        } else {
            setDeleteAcc(true)
        }
    }

    return <div>
        <Header {...val} />
        {userData && userData[0] && userData[0]['googleId'] ? 
        <div className="row">
        <div className="col-12 col-md-3 col-xl-3 mb-3 m-0">
            <div className="d-flex justify-content-center justify-content-md-start">
                <Sidebar {...userData[0]} location={location_.pathname} />
            </div>
        </div>
        <div className="col-12 col-md-9 col-xl-9 mb-3">
            Delete account 😭 
            <input onChange={(e) => setConfirmDelete(e.target.value)} className="form-control w-75" type="text" placeholder="Email address (it should match with your google account)" /> 
            <div className="my-2">
                <button onClick={() => onDeleteAcc(confirmDelete)} className="btn btn-danger">Delete Account</button>
            </div>
            {deleteAcc ? 'Invalid email Id.' : ''}
            {bye && 'Account Deleted 😭. Redirecting you to home page in 5s. '}
        </div>
    </div> : <div className="d-flex justify-content-center align-items-center">
        Please login.. <img className="ml-2" style={{ width: '1rem', height: '1rem' }} src="/gmail-login.png" alt="Please Login" />
    </div>}
    </div>
}

export default Settings;
