import { ActionType } from '../action-types';
import { Action } from '../actions'

interface AuthState {
    loading: boolean;
    error: string | null;
    data: string[];
}

const initialState = {
    loading: false,
    error: null,
    data: []
};

const reducer = (state: AuthState = initialState, action: Action): AuthState => {
    switch (action.type) {
        case ActionType.LOGIN_USER:
            return { loading: true, error: null, data: [] };
        case ActionType.LOGIN_USER_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.LOGIN_USER_ERROR:
            return { loading: false, error: action.payload, data:[] };
        default:
            return state
    }
}

export default reducer;