import axios from "axios";
import { ActionType } from "../action-types";
import { Action } from '../actions';
import { Dispatch } from "redux";

axios.defaults.withCredentials = true;
export const searchBlogPosts = (tags: any = 'all') => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SEARCH_BLOG_POSTS
        });

        try {
            const { data } = await axios.get(`https://jelenasrebricumd.herokuapp.com/api/v1/posts?${tags=='all' ? '' : `&tags=${tags[0].trim()}&tags=${tags[1].trim()}`}`);
            dispatch({
                type: ActionType.BLOG_POSTS_SUCCESS,
                payload: data.data.posts
            })

        } catch (err: any) {
            dispatch({
                type: ActionType.BLOG_POSTS_ERROR,
                payload: err.message
            });
        };
    };
};

export const logoutUser = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOGOUT_USER
        });
        try {
            await axios.get('https://jelenasrebricumd.herokuapp.com/api/v1/users/logout')
            dispatch({
                type: ActionType.LOGOUT_USER_SUCCESS,
                payload: ['Logged out']
            })
        } catch (err: any) {
            dispatch({
                type: ActionType.LOGOUT_USER_ERROR,
                payload: err.message
            });
        };

        
    }
}

export const loginUser = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.LOGIN_USER
        });
        try {
            const res  = await axios.get('https://jelenasrebricumd.herokuapp.com/api/v1/users/current_user');
            
            dispatch({
                type: ActionType.LOGIN_USER_SUCCESS,
                payload: [res.data]
            })
        } catch (err: any) {
            dispatch({
                type: ActionType.LOGIN_USER_ERROR,
                payload: err.message
            });
        };
    }
}

export const postBlog = (post: object) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.POST_BLOG
        });
        try {
            await axios.post('https://jelenasrebricumd.herokuapp.com/api/v1/posts', post);
        } catch (err: any) {
            dispatch({
                type: ActionType.POST_BLOG_ERROR,
                payload: err.message
            });
        };
    };
};

export const updateUser = (bookmarkIds: any[], userId: string) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.UPDATE_USER
        });
        try {
            const res = await axios.patch(`https://jelenasrebricumd.herokuapp.com/api/v1/users/${userId}`, {
                bookmarks: bookmarkIds
            });
            dispatch({
                type: ActionType.UPDATE_USER_SUCCESS,
                payload: res.data.data.updateUser.bookmarks
            })
        } catch (err: any) {
            dispatch({
                type: ActionType.UPDATE_USER_ERROR,
                payload: err.message
            });
        }
    }
}

export const getPost = (id: string) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_POST
        });
        try {
            const { data } = await axios.get(`https://jelenasrebricumd.herokuapp.com/api/v1/posts/${id}`);
            dispatch({
                type: ActionType.GET_POST_SUCCESS,
                payload: data.data.post
            })
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_POST_ERROR,
                payload: err.message
            });
        };
    }
}

export const postContactUs = (name: string, body: string, email: string) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.POST_CONTACTUS,
        });
        try {
            const { data } = await axios.post(`https://jelenasrebricumd.herokuapp.com/api/v1/contactUs`, {
                name,
                body, 
                email
            }, { withCredentials: true,  });
            dispatch({
                type: ActionType.POST_CONTACTUS_SUCCESS,
                payload: data.data.contactUs
            })

        } catch (err: any) {
            dispatch({
                type: ActionType.POST_CONTACTUS_ERROR,
                payload: err.message
            });
        };
    };
}

export const searchContactUs = (pageNo=1) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SEARCH_CONTACTUS
        });
        try {
            const { data } = await axios.get(`https://jelenasrebricumd.herokuapp.com/api/v1/contactUs`);
            dispatch({
                type: ActionType.SEARCH_CONTACTUS_SUCCESS,
                payload: data.data.contactUs
            })

            // ('data', data.data.contactUs)

        } catch (err: any) {
            dispatch({
                type: ActionType.SEARCH_CONTACTUS_ERROR,
                payload: err.message
            });
        };
    };
};

export const searchTutorialPosts = () => {
    // console.log('tags', tags)
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SEARCH_TUTORIAL_POSTS
        });

        try {
            const { data } = await axios.get(`https://jelenasrebricumd.herokuapp.com/api/v1/tutorials`);
            dispatch({
                type: ActionType.TUTORIAL_POSTS_SUCCESS,
                payload: data.data.tutorials
            })

        } catch (err: any) {
            dispatch({
                type: ActionType.TUTORIAL_POSTS_ERROR,
                payload: err.message
            });
        };
    };
};

export const getTutorial = (id: string) => {
    return async(dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_TUTORIAL
        });
        try {
            const { data } = await axios.get(`https://jelenasrebricumd.herokuapp.com/api/v1/tutorials/${id}`);
            dispatch({
                type: ActionType.GET_TUTORIAL_SUCCESS,
                payload: data.data.tutorial
            })
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_TUTORIAL_ERROR,
                payload: err.message
            });
        };
    }
}

export const postTutorial = (tutorial: object) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.POST_TUTORIAL
        });
        try {
            await axios.post('https://jelenasrebricumd.herokuapp.com/api/v1/tutorials', tutorial);
        } catch (err: any) {
            dispatch({
                type: ActionType.POST_TUTORIAL_ERROR,
                payload: err.message
            });
        };
    };
};

export const deleteUser = (userId: string) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.DELETE_USER
        });
        try {
            await axios.delete(`https://jelenasrebricumd.herokuapp.com/api/v1/users/${userId}` );
    
        } catch (err: any) {
            dispatch({
                type: ActionType.DELETE_USER_ERROR,
                payload: err.message
            });
        };
    }
    
}
