import TutorialsItems from "./tutorials-items";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Link } from "react-router-dom";
import { useActions } from "../../hooks/useAction";


const TutorialsList: React.FC = () => {
    const { searchTutorialPosts } = useActions();
    const { data } = useTypedSelector((state) => state.tutorialPostReducer)

    const [result, setResult] = useState<any[]>([]);
    useEffect(() => {
        searchTutorialPosts();
    }, [])

    useEffect(() => {
        setResult(data)
        console.log('data', result)
    }, [data])


    return <div>
        <div className="d-flex justify-content-between my-3">
            <div className="most-viewed-posts-heading">Tutorials</div>
        </div>
        <div className="row d-flex justify-content-start">
            {/* {result.map((val, index) => { */}
                <div className="col-lg-4" >
                     <Link to={{ pathname: `/course/${result && result[0] && result[0].tutorialTopic}`, state: result }} >
                    <TutorialsItems {...result[0]} length_={result && result.length} />
                    </Link>
                </div>
            {/* })} */}
        </div>
    </div>
};

export default TutorialsList;