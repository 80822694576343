import { ActionType } from '../action-types';
import { Action } from '../actions'

interface TutorialPostState {
    loading: boolean;
    error: string | null;
    data: string[];
}

const initialState = {
    loading: false,
    error: null,
    data: []
};

const reducer = (state: TutorialPostState = initialState, action: Action): TutorialPostState => {
    switch (action.type) {
        case ActionType.SEARCH_TUTORIAL_POSTS:
            return { loading: true, error: null, data: [] };
        case ActionType.TUTORIAL_POSTS_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.TUTORIAL_POSTS_ERROR:
            return { loading: false, error: action.payload, data: [] };
        case ActionType.POST_TUTORIAL:
            return { loading: true, error: null, data: [] };
        case ActionType.POST_TUTORIAL_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.POST_TUTORIAL_ERROR:
            return { loading: false, error: action.payload, data:[] };
        case ActionType.GET_TUTORIAL:
            return { loading: true, error: null, data: [] };
        case ActionType.GET_TUTORIAL_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.GET_TUTORIAL_ERROR:
            return { loading: false, error: action.payload, data:[] };
        default:
            return state
    }
}

export default reducer;