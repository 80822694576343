import { Link } from 'react-router-dom';
import './categories.css';

interface CategoryItemProps {
    categoryName: string;
    total: number;
    img: string
}

const CategoryItem: React.FC<CategoryItemProps> = ({categoryName, total, img}) => {
    return <div className="mb-4 category-card shadow-sm">
        
        <Link to={`/view-all-posts?tags=${categoryName.split('&')[0]}&tags=${categoryName.split('&')[1]}`}>
            <div className="p-3">
                <div className="category-card-title">
                    {categoryName}
                </div>
                <div className="category-card-total mt-2 d-flex justify-content-between">
                    <span>{total}</span>
                    <img src={img} alt={img} className="cat-img" />
                </div>
            </div>
        </Link>
    </div>
};

export default CategoryItem;