import { ActionType } from '../action-types';
import { Action } from '../actions'

interface BlogPostState {
    loading: boolean;
    error: string | null;
    data: string[];
}

const initialState = {
    loading: false,
    error: null,
    data: []
};

const reducer = (state: BlogPostState = initialState, action: Action): BlogPostState => {
    switch (action.type) {
        case ActionType.SEARCH_BLOG_POSTS:
            return { loading: true, error: null, data: [] };
        case ActionType.BLOG_POSTS_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.BLOG_POSTS_ERROR:
            return { loading: false, error: action.payload, data: [] };
        case ActionType.POST_BLOG:
            return { loading: true, error: null, data: [] };
        case ActionType.POST_BLOG_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.POST_BLOG_ERROR:
            return { loading: false, error: action.payload, data:[] };
        case ActionType.GET_POST:
            return { loading: true, error: null, data: [] };
        case ActionType.GET_POST_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.GET_POST_ERROR:
            return { loading: false, error: action.payload, data:[] };
        default:
            return state
    }
}

export default reducer;