import {  useState } from 'react';
import styled from 'styled-components';
import ContactPopup from './ContactPopup';
 

const ContactWidget: React.FC = () => {
    const [open, setOpen] = useState(false);

    return <WidgetContainer className="d-flex justify-content-end">
        
        <Widget onClick={() => setOpen(!open)}>
            <WidgetIcon className="d-flex justify-content-center align-items-center">
                <i style={{ fontSize: '1.25rem' }} className={`fas fa-${open ? 'times' : 'comment-alt'} text-white`}></i>
            </WidgetIcon>
        </Widget>
        {open ? 
        <ContactBox className="position-fixed">
            <ContactPopup  popup={(data) => setOpen(data)} /> 
        </ContactBox> : ''}
    </WidgetContainer>
}

export default ContactWidget;

export const WidgetContainer = styled.div`
    position: relative;
    top: 85vh;
`

export const Widget = styled.div`
    height: 4rem;
    width: 4rem;
    background-color: #ff3860;
    border-radius: 50%;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    margin-top: 1.5rem;

    @media(min-width: 1200px) {
        right: 9rem
    }
`

export const WidgetIcon = styled.div`
    position: relative;
    top: 1.5rem;
`

export const ContactBox = styled.div`
    position: relative;
    bottom: 7.5rem;
    z-index:2;

`