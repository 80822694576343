import { useEffect, useState } from "react";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import CategoryItem from "./category-item";


const CategoryList: React.FC = () => {
    const { searchBlogPosts } = useActions();
    const [result, setResult] = useState<any[]>([]);
    const { data: blogData, loading } = useTypedSelector((state) => state.blogPostReducer)
    const [catCount, setCatCount] = useState<any>({
        'Deep learning': 0,
        'Machine learning': 0,
        'Computer Vision': 0,
        'Python': 0,
        'JavaScript': 0,
        'HTML/CSS': 0,
        'Others': 0,
        'Algorithms': 0
    });
    const [render, setRender] = useState<boolean>(true)
    const [counter, setCounter] = useState<number>(0)

    useEffect(() => {
        searchBlogPosts()
        setRender(true)
    },[])

    useEffect(() => {
        setResult(blogData)
        setCounter(counter + 1)
        if(counter == 2) {
            setRender(true)
        } else {
            setRender(false)
        }
    }, [blogData]);

    useEffect(() => {
        if(render) {
            result.map((res, _) => {
                res && res.tags.map((t: string, _: number) => {
                    catCount[t] = catCount[t] + 1 || 0
                })
            })
        }
    }, [result])

    const data = [
        {
            categoryName: 'Deep learning & Machine learning',
            total: catCount && (catCount['Deep learning'] + catCount['Machine learning']),
            img:  require('./../../images/dl.png').default
        },
        {
            categoryName: 'Python & Computer Vision',
            total: catCount && (catCount['Python'] + catCount['Computer Vision']),
            img: "https://img.icons8.com/dusk/128/000000/python.png"
        },
        {
            categoryName: 'JavaScript & HTML/CSS',
            total: catCount && (catCount['JavaScript'] + catCount['HTML/CSS']),
            img: "https://img.icons8.com/dusk/64/000000/javascript.png"
        },
        {
            categoryName: 'Algorithms & Others',
            total: catCount && (catCount['Others'] + catCount['Algorithms']),
            img: "https://img.icons8.com/external-tal-revivo-tritone-tal-revivo/64/000000/external-level-up-your-coding-skills-and-quickly-land-a-job-logo-tritone-tal-revivo.png"
        }
    ]
    
    return <div>
         <div className="row">
            {data.map((info, index) => {
            return <div key={index} className="col-md-3">
                <CategoryItem {...info} />
            </div>
            })}
        </div> 
    </div>
}

export default CategoryList;