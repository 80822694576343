import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import BookmarkCard from "../components/BookmarkCard";
import Header from "../components/header/header";
import Sidebar from "../components/notebooks/sidebar";
// import { useSockets } from "../context/socket.context";
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";


interface BookmarkProps {
    googleId: string,
    bookmarks: [],
    _id: string,
    profileImage: string
}

const Bookmarks: React.FC = () => {
    const location = useLocation<BookmarkProps>();
    const { searchBlogPosts, loginUser } = useActions();
    const val  = location.state
    const { data: blogData } = useTypedSelector((state) => state.blogPostReducer)
    const [result, setResult] = useState<any[]>([]);
    // const { socket } = useSockets();
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);
    const location_ = useLocation();

    useEffect(() => {
        loginUser()
    },[]);

    useEffect(() => {
        setUserData(authData)
    });

    useEffect(() => {
        searchBlogPosts()
    },[]);
  
    useEffect(() => {
        setResult(blogData)
    });

    useEffect(() => {
        // socket.on('bookmark_action', (socket) => {
        //     loginUser();
        //     setUserData(authData)
        // })
    }, [])

    return <div>
        <Header {...val} />
        {userData && userData[0] && userData[0]['googleId'] ? 
        <div className="row">
        <div className="col-12 col-md-3 col-xl-3 mb-3 m-0">
            <div className="d-flex justify-content-center justify-content-md-start">
                <Sidebar {...userData[0]} location={location_.pathname} />
            </div>
        </div>
        {userData && userData[0] && userData[0]['bookmarks'].length > 0 ?
        <div className="col-12 col-md-9 col-xl-9 mb-3">
            <BookmarkCard bookmarks={userData && userData[0] && userData[0]['bookmarks']} posts={result} googleId={userData && userData[0] && userData[0]['_id']}  />
        </div> : <div className="d-flex justify-content-center align-items-center w-75">
            No bookmarks 😭. <Link to="/view-all-posts"> <mark className="highlight-sidebar font-italic ">View all Posts</mark></Link>
        </div> }
    </div> : <div className="d-flex justify-content-center align-items-center">
        Please login.. <img className="ml-2" style={{ width: '1rem', height: '1rem' }} src="/gmail-login.png" alt="Please Login" />
    </div>}
    </div>
}

export default Bookmarks;
