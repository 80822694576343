import './tutorials.css';
import moment from "moment";

interface TutorialsItemProps {
    title: string;
    tutorialTopic: string;
    createdAt: Date | string;
    lessons: number;
    img: string;
}

interface LengthProps {
    length_: number;
}

type Props = TutorialsItemProps & LengthProps;

const TutorialsItems: React.FC<Props> = ({title, createdAt, tutorialTopic, lessons, img, length_}) => {
    return <div className="shadow-sm lessons-card mt-3">
       <div className="row">
           <div className="col-6 lessons-img m-0">
            <img src="./spr.png" />
           </div>
           <div className="col-5 offset-1 mt-3 lessons-text m-0">
                <div className="fixed-height">
                    <div className="">
                        {tutorialTopic}
                    </div>
                    <div className="flex-column mt-3">
                        <div className="post-timing-text">Posted on</div>
                        <div className="post-timing-text post-timing-date">{moment(createdAt).format("lll")}</div>
                    </div>
                </div>
                <div className="text-left mt-3">
                    <span>
                        <span className="mr-1"><i className="fas fa-book"></i> {length_} </span>
                    </span>
                </div>
           </div>
       </div>
    </div>
};

export default TutorialsItems;