const Loading: React.FC = () => {
    return <div>
        <div className="loading-wrapper">
            <div className="bar one"></div>
            <div className="bar two"></div>
            <div className="bar three"></div>
        </div>
    </div>
}

export default Loading