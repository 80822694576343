import { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Link } from "react-router-dom";

import { useActions } from "../../hooks/useAction";
import BlogCardItem from "./blog-card-item";
import './blogCard.css';

interface BlogCardProps {
    googleId: string,
    bookmarks: [], 
    _id: string
}

const BlogCard: React.FC<BlogCardProps> = ({ bookmarks, _id}) => {
    const { searchBlogPosts } = useActions();
    const { data } = useTypedSelector((state) => state.blogPostReducer)
    const [result, setResult] = useState<any[]>([]);
    useEffect(() => {
        searchBlogPosts();
    }, [])

    useEffect(() => {
        setResult(data)
    }, [data])

    return <div>
        <div className="d-flex justify-content-between my-3">
            <span className="blog-posts-heading">Blog posts</span>
            <Link to="/view-all-posts">
                <span className="blog-posts-heading">See all({result && result.length || 0 })</span>
            </Link>
        </div>
        <div className="row d-flex justify-content-center justify-content-md-start">
            {result && result.length > 0 && result.map((val, index) => {
                bookmarks && bookmarks.map((id, _) => {
                    if (id == val._id) {
                        val.bookmarked = true
                    }
                })

                if (index <= 2) {
                    return <div key={index} className="col-lg-4">
                        <BlogCardItem {...val} googleId={_id} bookmarks={bookmarks}/>
                    </div>
                }
            })}
        </div>
        {/* <hr /> */}
    </div>
}

export default BlogCard;