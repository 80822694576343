
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
// import { useRef } from 'react';
import { useState } from 'react';
import { TwitterShareButton, WhatsappShareButton, LinkedinShareButton, FacebookShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface ShareModalProps {
    modal: boolean;
    modalData: string[];
    onSelectedChange: (modal: boolean) => void;
}


const ShareModal: React.FC<ShareModalProps> = ({modal, modalData, onSelectedChange}) => {
    const history = useHistory();
    const [link, setLink] = useState<string>(`http://localhost:3000${history.location.pathname}`);
    useEffect((): any => {
        document.body.style.overflow = 'hidden';
        return () => document.body.style.overflow = 'unset';
     }, []);

    useEffect(() => {
        const close = (e: KeyboardEvent) => {
          if(e.key === 'Escape'){
            onSelectedChange(!modal)
          }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    },[])

    const styles: any = {
        modal: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 1000,
            backgroundColor: 'rgba(33,37,41,0.8)',
            top: 0,
            display: 'flex'
        },
        modalContent: {
            width: '50vw',
            padding: '3rem',
            backgroundColor: '#fff',
            margin: 'auto',
            borderRadius: '0.5rem'
        }
    }

    return ReactDOM.createPortal(
        <div onClick={() => onSelectedChange(!modal)} style={styles.modal}>
            <div style={styles.modalContent} className="d-flex align-items-center justify-content-around flex-column flex-md-row">
                    <TwitterShareButton url={link}>
                        <i style={{fontSize: '2rem'}}  className={`fab fa-twitter m-md-0 m-3`}></i>
                    </TwitterShareButton>
                    <WhatsappShareButton url={link}>
                        <i style={{fontSize: '2rem'}}  className={`fab fa-whatsapp m-md-0 m-3`}></i>
                    </WhatsappShareButton>
                    <LinkedinShareButton url={link}>
                        <i style={{fontSize: '2rem'}}  className={`fab fa-linkedin-in m-md-0 m-3`}></i>
                    </LinkedinShareButton>
                    <FacebookShareButton url={link}>
                        <i style={{fontSize: '2rem'}}  className={`fab fa-facebook m-md-0 m-3`}></i>
                    </FacebookShareButton>
                    <CopyToClipboard text={link}>
                        <i style={{fontSize: '2rem', cursor:'pointer'}}  className={`fas fa-link m-md-0 m-3`}></i>
                    </CopyToClipboard>
            </div>
        </div>,
        document.getElementById('share-modal') as Element 
    );
};

export default ShareModal;
