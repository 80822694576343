import Accordion from "../components/accordion";
import CourseContent from "../components/course-content";
import { useEffect, useState } from "react";
import Header from "../components/header/header";
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useLocation, useHistory, useParams } from "react-router-dom";



const IndvTutorialPage: React.FC = () => {
    const location = useLocation<any>();
    const { searchTutorialPosts } = useActions();
    const { data, loading }: any = useTypedSelector((state) => state.tutorialPostReducer)
    const history = useHistory();
    const [acc, setAcc] = useState<any>()
    const [selected, setSelected] = useState<any>()
    const { loginUser } = useActions();
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);
    const [tutorialData, setTutorialData] = useState<any>();
    useEffect(() => {
        loginUser()
    },[]);

    useEffect(() => {
      searchTutorialPosts()
    }, [])

    useEffect(() => {
        setUserData(authData)
    });

    useEffect(() => {
      setTutorialData(data)
    });

    useEffect(() => {
      setAcc(tutorialData)
    })
    useEffect(() => {
      setSelected(tutorialData && tutorialData[0] && tutorialData[0].body)
    },[])
    


    const toggleAcc= (index: number) => {
      setAcc(acc.map((val:any, i:number) => {
        if (i === index) {
          val.open = !val.open
        } else {
          val.open = false;
        }
        return val;
      }))
    }

    return <div>
      <Header {...userData[0]} />
      <i role="button" className="fas fa-arrow-left" onClick={history.goBack}></i>
      <div className="one-heading">
  <h1>{tutorialData && tutorialData[0] && tutorialData[0].tutorialTopic}</h1>
</div>
        
      <div className="row">
        <div className="col-md-8 text-center">
          <CourseContent content={selected ||tutorialData && tutorialData[0] && tutorialData[0].body} />
        </div>
        <div className="col-md-4">
        <div style={{ fontSize: '1.5rem' }} className="display-4 font-weight-bold mb-2">Content</div>
            {acc && acc.length > 0 && acc.map((val:any, i:number) => {
            return <div key={i}>
              <Accordion onSelectedChange={setSelected} toggleAcc={toggleAcc} index={i} {...val} />
            </div>
            })}
        </div>
      </div>
    </div>
}

export default IndvTutorialPage;
