import MDEditor from "@uiw/react-md-editor";


interface CourseContentProps {
    content: string;
}

const CourseContent: React.FC<CourseContentProps> = ({ content }) => {
    return <div>
        <div className="text-left">
        <MDEditor.Markdown style={{ display: 'grid'}}  className="m-0" source={content}  /> 
        </div>
    </div>
}

export default CourseContent;
