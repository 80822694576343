import { Link } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { useActions } from "../hooks/useAction";
import MDEditor from '@uiw/react-md-editor';
import { useEffect, useState } from "react";
import { useTypedSelector } from "../hooks/useTypedSelector";
import Header from "../components/header/header";
import PostForm from "./ForMe/post-form";
import Contact from "./ForMe/contact";

interface Post {
    title: string;
    body: string;
    tags: string[];
    readTime?: string;
}

const Admin: React.FC<Post> = () => {
    const { postBlog, loginUser } = useActions();
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);

    useEffect(() => {
        loginUser()
    },[]);

    useEffect(() => {
        setUserData(authData)
    });


    return  <div className="mt-4">
        <Header {...userData[0]} />
        {userData && userData[0] && userData[0]['googleId'] === '103948883952043218214' ?
        <div>
            <div className="d-flex flex-column flex-md-row justify-content-between w-50">
                <Link to="/admin" className="post">
                    Post
                </Link>
                <Link to="/tutorials" className="post">
                    Tutorials
                </Link>
                <Link to="/contact-form">
                    Contact
                </Link>
                <div className="all_users">
                    All Users
                </div>
                <div className="location">
                    User Location
                </div>
            </div>
            <PostForm />
        </div>
        :  <div className="d-flex justify-content-center align-items-center">
            Only Admin can access this page! 😔
        </div>}
        
    </div> 
};

export default Admin;