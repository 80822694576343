import { ActionType } from '../action-types';
import { Action } from '../actions'

interface UserState {
    loading: boolean;
    error: string | null;
    data: string[];
}

const initialState = {
    loading: false, 
    error: null, 
    data: []
}

const reducer = (state: UserState = initialState, action: Action): UserState => {
    switch (action.type) {
        case ActionType.UPDATE_USER:
            return { loading: true, error: null, data: [] };
        case ActionType.UPDATE_USER_SUCCESS:
            return { loading: false, error: null, data: action.payload };
        case ActionType.UPDATE_USER_ERROR:
            return { loading: false, error: action.payload, data:[] };
        case ActionType.DELETE_USER:
            return { loading: true, error: null, data: [] }
        case ActionType.DELETE_USER_SUCCESS:
                return { loading: false, error: null, data: action.payload }
        default:
            return state
    }
}

export default reducer;