import Header from '../components/header/header';
import CategoryList from '../components/categories/category-list';
import BlogCard from '../components/blogCard/blog-card';
import TutorialsList from '../components/tutorials/tutorials-list';
import { useActions } from '../hooks/useAction';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../hooks/useTypedSelector';
// import { useSockets } from '../context/socket.context';
import Loading from '../components/Loading';

const Blog: React.FC = () => {
  const { loginUser, searchBlogPosts } = useActions();
  const { data } = useTypedSelector((state) => state.authReducer);
  const { data: blogData, loading } = useTypedSelector(
    (state) => state.blogPostReducer
  );
  const [userData, setUserData] = useState<any[]>([]);
  // const { socket } = useSockets();

  useEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    // socket.on('bookmark_action', (socket) => {
    //   loginUser();
    //   setUserData(data);
    // });

    return () => {};
  }, []);

  useEffect(() => {
    setUserData(data);
  });

  return (
    <div>
      <Header {...userData[0]} />
      <div>
        <div style={{ fontWeight: 500, fontSize: '1.8rem' }}>
          Hello{' '}
          {userData && userData[0] && (
            <mark>{userData && userData[0] && userData[0]['displayName']}</mark>
          )}
          !
        </div>
        <div
          style={{ marginTop: '.5rem', color: '#757575', fontSize: '.8rem' }}
          className='mb-3'
        >
          This is what I've got for you today.
        </div>
        <CategoryList />
        <BlogCard {...userData[0]} />
        <TutorialsList />
      </div>
    </div>
  );
};

export default Blog;
