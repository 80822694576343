import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/header";
import NotebookList from "../components/notebooks/notebook-list";
import Sidebar from "../components/notebooks/sidebar";
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useLocation } from 'react-router-dom'

const Dashboard: React.FC = () => {
    const { loginUser, searchBlogPosts } = useActions();
    const { data } = useTypedSelector((state) =>  state.blogPostReducer)
    const [result, setResult] = useState<any[]>([]);
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);
    const location = useLocation();

    useEffect(() => {
        loginUser()
        searchBlogPosts()
    },[]);

    useEffect(() => {
        setUserData(authData)
    });

    useEffect(() => {
        loginUser()
    }, []);

    useEffect(() => {
        setResult(data)
    })
    return <div className="mt-3">
        <Header {...userData[0]} />
        
        {userData && userData[0] && userData[0]['googleId'] ? <div className="row">
            <div className="col-md-3">
                <Sidebar {...userData[0]} location={location.pathname} />
            </div>
            <div className="col-md-9">
                {result && result[0] && result[0]._id ? <Link to={`/posts/${result && result[0] && result[0]._id}`} className="row post-of-the-day my-3 ">
                    <div className="d-flex col-md-7 align-items-center">
                        <div className="">
                            <div className="mt-3 mt-md-0">
                                Blog Post of the week!
                            </div>
                            <div style={{ fontSize: '2rem'}}>
                                {result && result[0] && result[0].title}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 d-none d-md-block">
                        <img style={{ height: '15rem' }} src='./happy.png' alt="blog post of the day!" />
                    </div>
                </Link>: ''}
                <NotebookList />
            </div>
        </div> : <div className="d-flex justify-content-center align-items-center">
            Please login.. <img className="ml-2" style={{ width: '1rem', height: '1rem' }} src="/gmail-login.png" alt="Please Login" />
        </div>}

    </div>
};

export default Dashboard;
