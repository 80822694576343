import { combineReducers } from "redux";
import blogPostReducer from './blogPostReducer';
import tutorialPostReducer from './tutorialPostReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import contactUsReducer from './contactUsReducer';

const reducers = combineReducers({
    blogPostReducer,
    tutorialPostReducer,
    authReducer,
    userReducer,
    contactUsReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;