import Header from '../components/header/header';
import CategoryList from '../components/categories/category-list';
import BlogCard from '../components/blogCard/blog-card';
import TutorialsList from '../components/tutorials/tutorials-list';
import { useActions } from '../hooks/useAction';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useTypedSelector } from '../hooks/useTypedSelector';
// import { useSockets } from '../context/socket.context';
import Typing from 'react-typing-animation';
import { Link } from 'react-router-dom';

import './home-page.css';

const HomePage: React.FC = () => {
  const { loginUser, searchBlogPosts } = useActions();
  const { data } = useTypedSelector((state) => state.authReducer);
  const { data: blogData, loading } = useTypedSelector(
    (state) => state.blogPostReducer
  );
  const [text, setText] = useState('');
  const [color, setColor] = useState('#004EF5');
  const [userData, setUserData] = useState<any[]>([]);
  // const { socket } = useSockets();

  useEffect(() => {
    loginUser();
  }, []);

  const AnimatedTypingComponent = () => (
    <Typing speed={250} hideCursor={true} loop={true}>
      <span style={{ position: 'absolute' }}>&nbsp;</span>
      <Typing.Reset count={1} delay={500} />
      <span className='h1 font-weight-bold' style={{ color: '#004EF5' }}>
        {' '}
        Professor
      </span>
      <Typing.Reset count={2} delay={500} />
      <span className='h1 font-weight-bold' style={{ color: '#7E00F5' }}>
        {' '}
        Researcher
      </span>
      <Typing.Reset count={3} delay={500} />
      <span className='h1 font-weight-bold' style={{ color: '#23D1AF' }}>
        {' '}
        Speaker
      </span>
      <Typing.Reset count={4} delay={500} />
    </Typing>
  );

  useEffect(() => {
    setUserData(data);
  });

  return (
    <div className=''>
      <Header {...userData[0]} />
      <div className='mt-5 d-flex justify-content-center flex-column align-items-center'>
        <div
          className='container-home d-flex justify-content-center flex-column align-items-start '
          style={{ minHeight: '50vh', width: '75%' }}
        >
          <h1 className='' style={{ fontWeight: 'bold' }}>
            Hi, I’m Jelena Srebric,{' '}
            <div className='d-flex'>
              <span className='h1'>a </span>
              &nbsp; {AnimatedTypingComponent()}
            </div>
          </h1>

          <p style={{ fontSize: '1.6rem' }}>
            I build <strong>design systems</strong> and{' '}
            <strong>code websites</strong> for leaders and brands all around the
            world.
          </p>

          <p style={{ fontSize: '1.6rem' }}>
            Within my <strong>5 years</strong> of experience, my professional
            work and open source contributions have already impacted{' '}
            <strong>thousands of people</strong>.
          </p>
          <div className='d-flex flex-column flex-md-row'>
            <Link
              to='/'
              style={{ textDecoration: 'none' }}
              className='button-module--button  button-module--color-primary mt-3'
              href='/freelance-designer-developer-london/'
            >
              See my Work
            </Link>
            {/* <Link
              to='/blog'
              style={{ textDecoration: 'none' }}
              className='button-module--button ml-0 ml-md-3  button-module--color-primary mt-3'
            >
              Blog
            </Link> */}
            <a
              href='https://zcal.co/i/USKBcUkX'
              target='_blank'
              style={{ textDecoration: 'none' }}
              className='button-module--button ml-0 ml-md-3  button-module--color-primary mt-3'
            >
              Setup a Meeting
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
