import { useLocation, useHistory, useParams } from "react-router-dom";
import "./indv-post.css";
import MDEditor from '@uiw/react-md-editor';
import { useEffect, useState } from "react";

import ShareModal from './../share-modal';
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface BlogPostValueProps {
    body: string;
    bookmarked: Boolean;
    createdAt: String;
    id: String;
    readTime: number;
    tags: String[];
    title: String;
}


const IndvPost: React.FC = () => {
    const { getPost } = useActions();
    const location = useLocation<BlogPostValueProps>()
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const { data, loading }: any = useTypedSelector((state) => state.blogPostReducer)
	const [modalData, setModalData] = useState<string[]>(['']);
    const [postData, setPostData] = useState<any>('')
	const onSendData = () => {
		setModal(true)
	}
    const val  = location.state
    let {id} : any = useParams();

    useEffect(() => {
        getPost(id)
    }, [])

    useEffect(() => {
        setPostData(data)
    })


    return <div>
        <i role="button" className="fas fa-arrow-left" onClick={history.goBack}></i>
        {modal ? <ShareModal modal={modal} onSelectedChange={setModal} modalData={modalData}/> : ''}
        <div className="row">
            <div className="col-md-12">
                <div style={{ color: 'gray', fontSize: '.8rem' }}>
                    {postData && postData['createdAt'] && postData['createdAt'].replace('T', ' ').slice(0 , 16)} &nbsp;
                    <i className="fas fa-clock"></i> {Math.ceil(postData && postData.body && postData.body.split(' ').length / 200)} mins 
                    &nbsp;
                    <i role="button" onClick={() => onSendData()} className="fas fa-share-alt">
                        
                    </i>
                </div>
                <div style={{ fontSize: '1.8rem' }} className="font-weight-bold">{postData && postData.title}</div>
                <div className="d-flex align-items-center">
                <div style={{ color: 'gray', fontSize: '.8rem' }}>Coding Environment Coming soon!</div>
                <div style={{ zIndex: 10 }} className="p-1 mt-2 ml-2 write-notes"><i className="fas fa-code"></i></div>
                </div>
            </div>
            {/* <div className="offset-2 col-md-4">
                <img className="m-0" style={{ width: '100%', height: '100%' }} src={postData && postData.imageCover} alt="blog post" />
            </div> */}
        </div>
        <div className="row container mt-2">
            <div  className="blog-post-text mb-4">
                <MDEditor.Markdown style={{ display: 'grid'}}  className="m-0" source={postData && postData.body}  />     
            </div>
        </div>
    </div>
};

export default IndvPost;
