import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/header";
import IndvPost from "../components/indvPost/indv-post";
import { useActions } from "../hooks/useAction";
import { useTypedSelector } from "../hooks/useTypedSelector";

const IndvPostPage: React.FC = () => {
    const { loginUser } = useActions();
    const { data: authData } = useTypedSelector((state) => state.authReducer)
    const [userData, setUserData] = useState<any[]>([]);

    useEffect(() => {
        loginUser()
    },[]);

    useEffect(() => {
        setUserData(authData)
    });
    return <div>
        <Header {...userData[0]} />
        <IndvPost />
    </div>
};

export default IndvPostPage;
