import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { useSockets } from "../context/socket.context";
import { useActions } from "../hooks/useAction";

export interface BookmarkProps {
  bookmarks: any[]
}

export interface GoogleId {
    googleId: string
  }

export interface PostProps {
    posts: any[]
}

type Props = BookmarkProps & PostProps & GoogleId;

const DashboardNewsCard: React.FC<Props> = ({ bookmarks, posts, googleId }) => {
    const book_: any = []
    const { updateUser, searchBlogPosts, loginUser } = useActions();
    const [bm, setBm] = useState<any[]>([]);
    const [r, setR] = useState<boolean>(false);
    // const { socket } = useSockets();

    useEffect(() => {
        const bm_ = posts && posts.length > 0 && posts.map((val, _) => {
            bookmarks && bookmarks.map((v, _) => {
            if(val._id === v){
                    return book_.push(val)
                }
            })
        })
        if(book_.length > 0 && r != true) {
            setBm(book_)
            setR(true)
        }
    })

  return (
    <div className="mb-3 row">
      {bm && bm.map((data_ , _: number) => {
          return <div
          key={_}
          className="col-md-6 col-lg-3 my-4 mt-md-0"
        >  
            <Card className="d-flex flex-column align-items-center justify-content-center">
            <Link to={{ pathname: `/posts/${data_._id}`, state: { title: data_.title, tags: data_.tags, createdAt: data_.createdAt, body: data_.body, bookmarked: data_.bookmarked, _id: data_._id }}}>
            <article>
                <div className="row m-0">
                <div className="p-3">
                    <h3
                    className=""
                    style={{ fontSize: ".5rem", color: "#bcc0c3" }}
                    >
                    {moment(data_.createdAt).format("lll")}
                    </h3>
                    <h3 style={{ fontSize: ".95rem"}}>
                    {data_ && data_.title &&  data_.title.length < 25
                        ? data_.title
                        : `${data_.title.substr(0, 30)}...`}
                    </h3>
                    <p
                    className=""
                    style={{
                        // height: '5rem',
                        fontStyle: "italic",
                        color: "#525c65",
                        fontWeight: 400,
                        fontSize: "0.6rem",
                        // lineHeight: "1.5rem",
                    }}
                    >
                    {data_ && data_.body.length < 25
                        ? data_.body
                        : `${data_.body.substr(0, 50)}...`}
                    </p>
                    <div className="d-flex justify-content-between">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            e.preventDefault();
                            const bookmarkRemoved = bookmarks.filter((bm:any) => bm !== data_._id);
                            updateUser([...bookmarkRemoved], googleId);
                            searchBlogPosts();
                            // socket.emit('bookmark');
                        }}
                        className="p-2 bg-light rounded-3"
                    >
                        <i className="fas fa-trash"></i>
                    </div>
                    </div>
                </div>
                </div>
            </article>
            </Link>
            </Card>
        </div>
      })}
    </div>
  );
};

export default DashboardNewsCard;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 5px 5px 15px 0 rgb(46 61 73 / 20%);
  border-radius: 1.375rem;
  height: 12rem;
`;