import { useEffect, useState } from 'react';
import './accordion.css';
import MDEditor from '@uiw/react-md-editor';

interface AccordionProps {
    title: string;
    body: string;
    difficulty: string;
    readTime: string;
    index: number;
    open: boolean;
    onSelectedChange: (selected: string) => void;
    toggleAcc: (index: number) => void;
}

const Accordion: React.FC<AccordionProps> = ({ open, toggleAcc, title, body, difficulty, readTime, onSelectedChange, onSelectedIndex, index} : any) => {
    // const [open_, setOpen] = useState<boolean | null>(false)
    const [color, setColor] = useState<string>('');
    const [selectedColor, setSelectedColor] = useState<boolean>(false);
    // const [active, setActive] = useState<boolean | null>(false);
    useEffect(() => {
        return () => {
            setSelectedColor(false)
        }
    }, [selectedColor]);

    useEffect(() => {
        if(difficulty === 'Hard') {
            setColor('danger')
        } else if (difficulty === 'Medium') {
            setColor('info')
        } else {
            setColor('success')
        }
    }, [difficulty])


    return <div onClick={() => onSelectedChange(body)}>
        <span className={`border p-2 mt-2 shadow-sm d-flex justify-content-between align-items-center`} onClick={() => {toggleAcc(index)}}>
            {title} <i className={`fas fa-chevron-${open ? 'down': 'right'}`}></i>
        </span>
        {open ? 
            <div>
                <span  className={`badge badge-${color} mt-1`}>{difficulty}</span>
                <span style={{ background: '#4b7e92', color: 'white' }} className={`badge mt-1 ml-2`}>{Math.ceil(body.split(' ').length / 200)} min(s)</span>
                <div className="mt-2">
                <MDEditor.Markdown style={{ display: 'grid'}}  className="m-0" source={`${body.substring(0, 100)}...`} /> 
                    
                    
                </div>
            </div>
        : ''}
    </div>
};

export default Accordion;
